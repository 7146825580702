<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4">
                <MySidebar active="myfavorite" :user="user.userName" :image="user.image" />
            </div>
            <div class="col-lg-9 col-md-8">
                <div class="left-profile mt-5 pt-4 address-card">
                    <div class="header-profile">
                        <h2>
                            {{ $t("My favorite products") }}
                        </h2>
                    </div>

                    <div class="new m-2">
                        <div class="row" style="min-height: 800px" v-if="list.length > 0">
                            <div class="col-md-4 mb-2 p-3" v-for="item of list">
                                <div class="newCard">
                                    <img src="/images/unlike.svg" class="like" @click="addLike(item.itemId)"
                                        :alt="$getByLang(item.itemId.name)" />
                                    <span v-if="item.hasOffer == true" class="itemOfferRight">
                                        {{ $t("rival") }}
                                        {{ item.offer.offerValue }}%
                                    </span>

                                    <img class="logo" @click="$router.push('/product/' + item.itemId._id)" :src="item.itemId.colorsList.length > 0 &&
                                            item.itemId.colorsList[0].colorsImage.length > 0
                                            ? $baseUploadURL +
                                            item.itemId.colorsList[0].colorsImage[0].name
                                            : ''
                                        " :alt="$getByLang(item.itemId.name)" />

                                    <div class="newCard-body" @click="$router.push('/product/' + item.itemId._id)">
                                        <h5 class="text-truncate">{{ $textSlice($getByLang(item.itemId.name), 40) }}
                                        </h5>
                                        <!-- {{ $textSlice($getByLang(item.itemId.name), 40) }} -->
                                        <b-form-rating v-model="item.itemId.rate" variant="warning" class="mb-2"
                                            readonly inline no-border size="sm" locale="ar-EG"></b-form-rating>
                                        <span> ({{ item.itemId.rateAmount }}) </span>

                                        <!-- <p v-if="item.hasOffer == false">{{ item.itemId.sizes[0].mainPrice }} {{ $t("SAR") }}</p> -->
                                        <!-- <p v-if="item.hasOffer == true && item.itemId.sizes.length > 0">
                        {{
                          (item.itemId.sizes[0].mainPrice -
                          item.itemId.sizes[0].mainPrice * (item.offer.offerValue / 100)).toFixed(2)
                        }}
                        {{ $t("r.s") }}
                        <del class="offeroldprice">
                           {{ item.itemId.sizes.length > 0 ? item.itemId.sizes[0].mainPrice : 0 }}
                          {{ $t("r.s") }}
                        </del>
                      </p> -->

                                        <div class="price-container" v-if="item.itemId.sizes.length > 0">
                                            <p class="current-price" :class="{ hidden: !item.hasOffer }">
                                                {{
                                                    (item.itemId.sizes[0].mainPrice -
                                                        item.itemId.sizes[0].mainPrice * (item.hasOffer ? item.offer.offerValue
                                                            / 100 : 0)).toFixed(2)
                                                }}
                                                {{ $t("r.s") }}
                                            </p>
                                            <p v-if="item.hasOffer" class="old-price">
                                                <del>
                                                    {{ item.itemId.sizes[0].mainPrice }} {{ $t("r.s") }}
                                                </del>
                                            </p>
                                            <p v-else class="single-price">
                                                {{ item.itemId.sizes[0].mainPrice }} {{ $t("r.s") }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <infinite-loading @infinite="loadMore" :identifier="pageNum">
                            <div slot="no-more">{{ $t('No more data') }}</div>
                            <div slot="no-results">{{ $t('No results') }}</div>
                        </infinite-loading>


                        <!-- <Paginator :pageNumber="pageNum" v-if="list.length > 0" style="direction: ltr" :rows="12" :totalRecords="totalItemsCount" @page="onPage($event)"></Paginator> -->
                        <div class="m-5 text-center" v-if="list.length == 0">

                            <img src="../../assets/images/noFavoriteillustration.svg" class="mt-5" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";

export default {
    components: { InfiniteLoading },
    data() {
        return {
            pageNum: sessionStorage.homeHerePageMyfavorite == "myfavorite" ?
                parseInt(sessionStorage.homeHerePageNumberMyfavorite) : 1,
            user: {
                userName: null,
                email: null,
                phone: null,
                image: null,
            },
            body: {
                userName: null,
                email: null,
                phone: null,
                image: null,
            },
            list: [],
            totalItemsCount: 0,
        };
    },
    methods: {
        addLike(item) {
            if (localStorage.homeHere) {
                this.$http
                    .post("favourite", {
                        itemId: item._id,
                    })
                    .then((res) => {
                        item.imLikedThis = !item.imLikedThis;
                        this.$swal({
                            title: this.$t("Successfully Done"),
                            icon: "success",
                            timer: 3000,
                            showConfirmButton: false,
                        });
                    });
            } else {
                this.$toast.add({
                    severity: "error",
                    summary: this.$t("Error"),
                    detail: "Must be loged in",
                    life: 3000,
                });
            }
        },
        $goToSave(link) {
            sessionStorage.setItem("homeHerePageMyfavorite", "myfavorite");
            sessionStorage.setItem("homeHerePageNumberMyfavorite", this.pageNum);
            this.$router.push(link);
        },
        onPage(event) {
            //event.page: New page number
            this.pageNum = event.page + 1;
            this.getData(event.page + 1);
            sessionStorage.setItem("homeHerePageMyfavorite", "myfavorite");
            sessionStorage.setItem("homeHerePageNumberMyfavorite", this.pageNum);
            window.scrollTo(0, 0);
        },
        loadMore($state) {
            this.pageNum += 1;
            this.getData2(this.pageNum)
                .then((res) => {
                    if (res.length) {
                        this.list = [...this.list, ...res];
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                })
                .catch(() => {
                    $state.complete();
                });
        },
        async getData2(page = 1) {
            const res = await this.$http.post(`favourite/getFavouritesByUser?limit=12&page=${page}`, {});
            return res.data.docs;
        },
        getData(page = 1) {
            this.$http
                .get(`favourite/getFavouritesByUser?limit=12&page=1`)
                .then((res) => {
                    this.list = res.data.docs;
                    this.totalItemsCount = res.data.totalDocs;
                });
        },
    },
    created() {
        this.$http.post("users/getUserById", {}).then((res) => {
            this.user = res.data;
        });
        // this.$http.get("favourite/getFavouritesByUser").then((res) => {
        //   this.list = res.data.docs;
        // })
        this.getData();
    },
};
</script>

<style scoped>
.price-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 50px;
    /* تحديد ارتفاع أدنى لضمان الاتساق */
}

.current-price.hidden {
    visibility: hidden;
    /* إخفاء السعر العلوي بدون إزالة مكانه */
}

.current-price {
    font-size: 24px;
    /* أو الحجم الذي تراه مناسبًا */
    font-weight: bold;
    color: #000;
    /* اللون الذي تريده للنص */
    margin: 0;
}

.single-price {
    font-size: 24px;
    /* أو الحجم الذي تراه مناسبًا */
    font-weight: bold;
    color: #000;
    /* اللون الذي تريده للنص */
    margin: 0;
}

.old-price {
    font-size: 18px;
    /* أو الحجم الذي تراه مناسبًا */
    color: #888;
    /* اللون الذي تريده للنص المشطوب */
    margin: 0;
}

.old-price del {
    color: #888;
    /* اللون الذي تريده للنص المشطوب */
}
</style>
